export const data = [
  {
    fname: "Amit",
    lname: "Kulkarni",
    age: 25,
    gender: "male",
  },
  {
    fname: "Sachin",
    lname: "Kumar",
    age: 23,
    gender: "male",
  },
  {
    fname: "Ali",
    lname: "Khan",
    age: 17,
    gender: "male",
  },
  {
    fname: "Sujata",
    lname: "Kulkarni",
    age: 22,
    gender: "female",
  },
  {
    fname: "Vimal",
    lname: "Kumari",
    age: 20,
    gender: "female",
  },
  {
    fname: "Tej",
    lname: "Singh",
    age: 21,
    gender: "male",
  },
  {
    fname: "Simran",
    lname: "Shah",
    age: 19,
    gender: "female",
  },
  {
    fname: "Kiran",
    lname: "Shetty",
    age: 30,
    gender: "male",
  },
];
